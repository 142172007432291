<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Panel header="Configuracion de Polizas Automaticas">
                    <Toast />
                <DeleteDialog @closed="visibleDeleteDialog = false" @deleted="deleteConfig" v-model="visibleDeleteDialog" />
                <Helper @selected="configSelected" :headers="headers" :rows="entities" v-model="configHelper"/>
                <Loader v-model="loading" />
                <BasicFormToolbar @delete="visibleDeleteDialog = true" @new="newConfig" @save="save" @refresh="refresh" @search="configHelper.visible = true" :actions="['new','delete', 'refresh', 'save', 'search']" />
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Fieldset legend="Informacion General">
                            <div class="formgrid grid p-fluid">
                                <FormDropdown label="Tipo" wrapperClass="field col-3" :options="types" :disabled="entity.details.length > 0" optionLabel="label" optionValue="id" v-model="entity.type" :valid="validate.validations.type" />
                                <FormDropdown label="Tipo de Poliza" wrapperClass="field col-3" :options="policy_types" optionLabel="name" optionValue="id" v-model="entity.id_accounting_policy_type" :valid="validate.validations.id_accounting_policy_type" />
                                <FormInputText label="Descripcion" wrapperClass="field col-6" v-model="entity.description" :valid="validate.validations.description" />
                            </div>
                        </Fieldset>
                    </div>
                    <div class="col-12">
                        <Fieldset legend="Partidas">
                            <BasicFormToolbar @new="newDetail" @save="saveDetail" :actions="['new', 'save']" />
                            <div class="formgrid grid p-fluid">
                                <FormInputSwitch label="Cuenta Dinamica" :wrapperClass="'col-1 field'" v-model="detail.is_dynamic_account" />
                                <FormDropdownComplex :filterFields="['id_key', 'name']" wrapperClass="field col-2" label="Cuenta" v-model="detail.id_accounting_ledger_account" :disabled="detail.is_dynamic_account"
                                :options="accounts" optionLabel="id_key" optionValue="id" :labelInOption="'id_key - name'" :labelInValue="'id_key - name'"  />
                                <FormDropdown :wrapperClass="'col-2 field'" label="Tipo" v-model="detail.type" :valid="validateDetail.validations.type" :options="[{ id: 'CARGO' }, { id: 'ABONO' }]" :optionLabel="'id'" :optionValue="'id'" />
                                <FormDropdown :wrapperClass="'col-2 field'" label="Auxiliar" v-model="detail.helper_type" :valid="validateDetail.validations.helper_type" :options="[{ id: 'N/A' }, { id: 'CLIENTE' }, { id: 'PROVEEDOR' }, { id: 'CONCEPTO' }, { id: 'EMPLEADO' }]" :optionLabel="'id'" :optionValue="'id'" />
                                <FormDropdown :wrapperClass="'col-2 field'" label="Concepto" v-model="detail.concept" :valid="validateDetail.validations.concept" :options="concepts" :optionLabel="'id'" :optionValue="'id'" />
                                <FormInputText :wrapperClass="'col-2 field'" label="Comentario" v-model="detail.comment" />
                                <FormInputSwitch label="Modificable" :wrapperClass="'col-1 field'" v-model="detail.can_modify" />
                                <div class="col-12">
                                    <BasicDatatable @deleted="deleteDetail" @edited="editDetail" :rows="entity.details" :headers="detailHeaders" :rowaction="true" :rowedit="true" :rowdelete="true" />
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                </div>
                </Panel>
            </div>
        </div>
    </div>
</template>

<script>
import FormInputSwitch from '../../../components/general/FormInputSwitch.vue';
import FormInputText from '../../../components/general/FormInputText.vue';
import FormDropdown from '../../../components/general/FormDropdown.vue';
import FormDropdownComplex from '../../../components/general/FormDropdownComplex.vue';

import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import Helper from '../../../components/general/HelperDialog.vue';
import DeleteDialog from "../../../components/general/DeleteDialog.vue";

import Loader from '../../../components/general/Loader.vue';
import Session from '../../../mixins/sessionMixin';
import { ErrorToast, Rule, HeaderGrid, validate, fillObject, Toast } from '../../../utilities/General';
import { AccountingPolicyType } from '../../../models/contabilidad/AccountingPolicyType';
import { AccountingPolicyConfiguration } from '../../../models/contabilidad/AccountingPolicyConfiguration';
import { AccountingPolicyConfigurationDetail } from '../../../models/contabilidad/AccountingPolicyConfigurationDetail';
import { AccountingLedgerAccount } from '../../../models/contabilidad/AccountingLedgerAccount';

export default {
    components: { FormDropdownComplex, DeleteDialog, Helper, FormInputSwitch, BasicDatatable, BasicFormToolbar, FormDropdown, FormInputText, Loader },
    mixins: [Session],
    data() {
        return {
            entity: new AccountingPolicyConfiguration(),
            detail: new AccountingPolicyConfigurationDetail(),
            entities: [],
            configHelper: {
                visible: false
            },
            visibleDeleteDialog: false,
            loading: false,
            policy_types: [],
            accounts: [],
            headers: [
                new HeaderGrid('Tipo', 'type'),
                new HeaderGrid('Tipo Poliza', 'accounting_policy_type_name'),
                new HeaderGrid('Descripcion', 'description'),
                
            ],
            detailHeaders: [
                new HeaderGrid('Partida', 'id_consecutive'),
                new HeaderGrid("Cuenta Contable", "accounting_ledger_account", { type:"upper", formula: "search", expression: "find", data: [], data_key: "id", data_search_key: "id_accounting_ledger_account", data_value: "id_key"}),
                new HeaderGrid('Tipo', 'is_charge_type', { formula: 'boolean_express', expression: 'is_charge', true_res: "CARGO", false_res: "ABONO"}),
                new HeaderGrid('Auxiliar', 'helper_type'),
                new HeaderGrid('Concepto', 'concept'),
                new HeaderGrid('Comentario', 'comment'),
                new HeaderGrid('Modificable', 'can_modify', { type: 'boolean' }),
            ],
            types: [
                { id: 'VENTA_FACTURACION', label: 'VENTA (POR FACTURACION)' },
                { id: 'NOTA_CREDITO_FACTURACION', label: 'NOTA DE CREDITO (FACTURACION)' }],
            rules: [new Rule({ name: 'type' }), new Rule({ name: 'id_accounting_policy_type' }), new Rule({ name: 'description' })],
            validate: {
                valid: false,
                validations: {
                    type: null,
                    id_accounting_policy_type: null,
                    description: null,
                },
            },
            rulesDetail: [new Rule({ name: 'type' }), new Rule({ name: 'helper_type' }), new Rule({ name: 'id_accounting_ledger_account' }), new Rule({ name: 'concept' })],
            validateDetail: {
                valid: false,
                validations: {
                    type: null,
                    helper_type: null,
                    concept: null,
                    id_accounting_ledger_account: null,
                },
            },
        };
    },
    computed: {
        concepts() {
            return this.entity.type == 'VENTA_FACTURACION' ? [{ id: 'Base' }, { id: 'Total' }, { id: 'Impuesto' }, { id: 'Total * Tasa' }, { id: 'Impuesto * Tasa' }] 
            : this.entity.type == 'NOTA_CREDITO_FACTURACION' ? [{ id: 'Base' }, { id: 'Total' }, { id: 'Impuesto' }, { id: 'Total * Tasa' }, { id: 'Impuesto * Tasa' }] 
            : [];
        },
    },
    watch: {
        'detail.is_dynamic_account'(newValue) {
            this.detail.id_accounting_ledger_account = newValue ? null : this.detail.id_accounting_ledger_account;
        },
        'entity.type'() {
            this.detail.concept = null;
        },
    },
    methods: {
        async deleteConfig() {
            this.visibleDeleteDialog = false;
            try {
                this.loading = true;
                if (!this.entity.id) 
                    throw "ERROR. Seleccione un registro";
                await this.entity.delete();
                this.entities = this.entities.filter(x => x.id != this.entity.id);
                this.newConfig();
                this.$toast.add({
                        severity: 'success',
                        summary: 'Eliminacion',
                        detail: 'Registro eliminado con exito',
                        life: 3000,
                    });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        configSelected(config) {
            this.entity = fillObject(this.entity, config);
        },
        deleteDetail(detail) {
            let id_consecutive = detail.id_consecutive;
            this.entity.details = this.entity.details.filter((x) => x.id_consecutive != detail.id_consecutive);
            this.entity.details.forEach((x) => {
                if (x.id_consecutive > id_consecutive) {
                    x.id_consecutive = x.id_consecutive - 1;
                }
            });
        },
        newConfig() {
            this.entity = new AccountingPolicyConfiguration(this.session);
            this.newDetail();
        },
        editDetail(detail) {
            this.detail = fillObject(this.detail, detail);
            this.detail.type = this.detail.is_charge ? "CARGO" : "ABONO";
        },
        newDetail() {
            this.detail = new AccountingPolicyConfigurationDetail(this.session);
        },
        saveDetail() {
            try {
                this.rulesDetail.find(x => x.name == "id_accounting_ledger_account").active = !this.detail.is_dynamic_account;
                this.validateDetail = validate(this.detail, this.rulesDetail);
                if (!this.validateDetail.valid) throw 'Favor de validar los campos';
                this.detail.is_payment = this.detail.type == "ABONO";
                this.detail.is_charge = this.detail.type == "CARGO";
                if (this.detail.id_consecutive) this.entity.details[this.entity.details.findIndex((x) => x.id_consecutive == this.detail.id_consecutive)] = { ...this.detail };
                else {
                    this.detail.id_consecutive = this.entity.details.length + 1;
                    this.entity.details.push({ ...this.detail });
                }
                this.newDetail();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        async save() {
            this.loading = true;
            try {
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) throw 'Favor de validar los campos';
                if (this.entity.id && this.entity.id > 0) {
                    //* Actualizamos
                    let entity = await this.entity.update();
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Actualizar',
                        detail: 'Informacion actualizada con exito',
                        life: 3000,
                    });
                    let index = this.entities.findIndex((x) => x.id == this.entity.id);
                    this.entities[index] = entity;
                } else {
                    let entity = await this.entity.save();
                    this.entities.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: 'Creacion',
                            detail: 'Informacion guardada con exito',
                        })
                    );
                }
                this.entity = new AccountingPolicyConfiguration(this.session);
                this.detail = new AccountingPolicyConfigurationDetail(this.session);
                this.newDialog = false;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async refresh() {
            try {
                this.loading = true;
                this.policy_types = await new AccountingPolicyType(this.session).all();
                this.accounts = await new AccountingLedgerAccount(this.session).all();
                this.entities = await this.entity.all();
                //* Asignamos el data 
                let TipoPolizaHeader = this.headers.find(x => x.name == "Tipo Poliza");
                TipoPolizaHeader.data = this.policy_types;
                TipoPolizaHeader.data_key = "id";
                TipoPolizaHeader.data_value = "name";
                TipoPolizaHeader.data_search_key = "id_accounting_policy_type";
                TipoPolizaHeader.formula = "search";
                TipoPolizaHeader.expression = "find";
                //* Asignamos otro data
                this.detailHeaders[1].data = this.accounts;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
    async mounted() {
        this.entity = new AccountingPolicyConfiguration(this.session);
        this.detail = new AccountingPolicyConfigurationDetail(this.session);
        this.refresh();
    },
};
</script>

<style>
</style>