import {
    Abstract
} from './Abstract';

export class AccountingPolicyConfigurationDetail extends Abstract {
    constructor(session) {
        super('accounting/AccountingPolicyConfigurationDetail', session);
        this.id_accounting_policy_configuration = null;
        this.id_consecutive = null;
        this.id_accounting_ledger_account = null;
        this.is_payment = false;
        this.is_charge = false;
        this.type = "CARGO";
        this.helper_type = 'N/A';
        this.helper = '';
        this.id_accounting_cost_center = null;
        this.concept = null;
        this.comment = null;
        this.can_modify = false;
        this.is_dynamic_account = false;
    }
}